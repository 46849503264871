.pgs-card {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: box-shadow 0.3s;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.pgs-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pgs-img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 16px;
}

.pgs-details {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-direction: column;
}

.pgs-title {
  font-size: 0.9rem;
  color: #333;
  margin: 0;
}

.pgs-title a {
  color: inherit;
  text-decoration: none;
}

.pgs-price {
  font-size: 1rem;
  color: #000;
  margin: 0;
}

.pgs-original-price {
  text-decoration: line-through;
  color: #888;
  margin-left: 8px;
}

.pgs-actions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.pgs-icon {
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s;
}

.pgs-icon:hover {
  color: #ff4d4f;
}

.pgs-wishlist {
  color: #ff4d4f;
}

.pgs-icon[disabled] {
  cursor: not-allowed;
  color: #ccc;
}

.pgs-cart {
  color: #1890ff;
}
